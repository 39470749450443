import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import smoothscroll from 'smoothscroll-polyfill';
import Homepage from "./Homepage/Homepage";
import BackStage from "./BackStage";
import ESG from './Application/ESG';
import PowerManagement from './Application/PowerManagement';
import GIFViewer from './GIFViewer';

import "./reset.sass";
smoothscroll.polyfill();
const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/application/esg" component={ESG} />
        <Route path="/application/ems" component={PowerManagement} />
        <Route path="/backStage" component={BackStage} />
        <Route path="/gif" component={GIFViewer} />
        <Route exact path="/" component={Homepage} />
      </Switch>
    </Router>
  );
};

export default App;
